.App {
/*  text-align: center;*/
  margin: 0;
}

#root {
  margin: 0;
  padding: 0;
}

body { font-family: "EB Garamond", sans-serif; }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

a {
  color:#261532;
  text-decoration: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.stepImg img {
  border-radius: 20px;
  border:1px solid #cccccc;
  width:100%;
  height:100%
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px)
  }
}

.Introduction {
  font-size:1em;
  color:#000000;
}

.Introduction div {
  margin:0.5em;
  padding:0.1em;
}

.step {
  margin: 0.2em;
}

.subStep-r {
  max-width:75%;

}

.subStep-l {
  max-width:80%;
}

#navigation {
  font-weight:bold;
  margin:0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: right;
  padding: 0.1em;
  font-size: 1.2em;
}

#navigation .subnav {
  font-weight:bold;
  width:max-width;
  text-decoration: none;
  padding: 0.5em;
  margin:0.5em;
  padding:0.5em;
  
}

.subnav-cta {
  background-color: #5c79f6;
  color: white;
  border-radius: 25px;
  padding: 0.5em;
  margin:0.5em;
  padding:0.5em;
}

li {

  list-style-position: inside;
  font-weight: bold;
}

.pricingBlock {
 padding:1.5em;
 color:#000000;
 margin: 1em;
 border-radius:10px;
 border:1px solid #cccccc;
 background-color: #f9f7f5;
}

.pricingButton {
  text-align: center;
    background-color: #5c79f6;
    color: white;
    border-radius: 15px;
    padding: 0.5em;
    margin:0.5em;
    padding:1em;
    font-size:1.2em;
    font-weight:bold;
}

.pricingList {
  text-align: left;
  margin:2em;
  margin-left:0;
  margin-top:0.5em;
  font-size: 1.1em;
  position: relative;
  left:-20px;
}

.pricingList li {
  margin: 10px;
}

.flashBadge {
  font-weight:bold;
  background-color:#eeeae7;
  border-radius:5px;
  padding:10px;
  width:50%;
  text-align:center;
  border:1px solid #607cee;
}

.pricingHeader {
  /* background-color: #5c79f6; */
  padding:1em;
 /*  border-top-left-radius: 10%; */
  /* border-top-right-radius: 10%; */
  font-size:0.8em;
  padding-top:0.2em;
  margin:0;
  color: #261532
}

.pricingBox {
  margin:1em;
  padding:1em;
  background-color: #eeeae7;
}

.pricingPrice {
  /* background-color: #fcfd91; */
  font-size:3em;
  font-weight:bold;
  color:black;
  line-height: 1.5em;
  margin-left:10px;
}

.pricingSubprice {
  font-weight:bold;
  color:black;
  position:relative;
  top:-55px;
  left:85px;
  padding-bottom:1em;
  width:40px;
  float:left;
}

.pricingPayLink {

  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
}

.pricingPlanName {
  font-size:2em;
  font-weight:bold;
  margin-top:20px;
}

.pricingDesc {
  font-size: 1.5em;
  padding:0;
  margin:0;
  position:relative;
  bottom: 0.5em;
  margin-top:20px;
}

#subtitle {
  font-weight:bold;
  @media screen and (min-width: 360px) {
    font-size:3em;
  };
  @media screen and (min-width: 600px) {
    font-size:5em;
  };
  line-height:1em;

  color: #261532;
  text-align: center;
}

.subTextHeader {
  padding:0.5em;
  @media screen and (min-width: 360px) {
    font-size:1.2em;
  };
  @media screen and (min-width: 600px) {
    font-size:2em;
    width:60%;
  };
}

#intro {
  color:#000000;
  font-size:1em;
  display:flex;
  justify-content: center;
  text-align:center;
}

#introbox {
  justify-content: flex-start;
  width:100%;
}

.stepContainer {
  margin:0.5em;
  font-size:0.4em;
  padding:1em;
}

#symbolImage {
  text-align: center;
  width: 100%;
  height: 100%;
  margin-left:2em;
}

@media screen and (min-width: 360px) {


  .stepContainer {
    padding: 2em;
    margin: 1em;
    font-size: 0.85em;
  };
}

@media screen and (min-width: 600px) {

}

#pricingHeader {
  font-size:2em;
  font-weight:bold;
  padding-left:0;
  text-align:center;
}

#pricingDesc {
  color:#000000;
  padding-left:0;
  text-align:center;
}

h1 {

  text-align: center;
  margin-bottom: 40px;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 3em;
}

.info {
  max-width: 360px;
  margin: 0px auto 0;
  text-align: center;
  font-size: 16px;
}

.countdown {
  text-align: center;
}

.recording-now {
  font-size: 1.5em;
  margin:2em;
}

.recordingButtons {
  margin:1em;
}

#containerBars {

  position: sticky;
  top: 60px;

  z-index:5;
  margin:0;
}

.recording-context {
  font-weight: bold;
  font-size: 1.2em;
}

.contextTitle {
  font-weight: bold;
}




/* Dropdown Button */
.dropbtn {
  background-color: #f2f2f2;
  padding: 5px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  background-color: #f2f2f2;
  border-radius:10px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: block;
  position: absolute;
  margin-left: 0;
  background-color: #f2f2f2;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  left: -120px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: 1px solid #cccccc;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
/* .dropdown:hover .dropdown-content {display: block;} */

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #cccccc;}

.navbarA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #607cee;
  padding: 10px;
}

.navbarB {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  padding: 5px;
}

.buttonsA {
  display: flex;
}

/* Optional styles for the buttons */
.buttonsA button {
  background-color: #f5f5f5;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Optional hover effect for the buttons */
.buttons button:hover {
  background-color: #f5f5f5;
}


ul[role=navigation] {
  font-size: 20;
}



.red{
  background: #ed5e5b;
}


.centerrec {
display: flex;
justify-content: space-evenly;
align-items: center;

}

.circle {
width: 80px;
height: 80px;
border-radius: 50%;
box-shadow: 100px 70px 170px 100px #0000001a;
}

.pulse {
animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
0% {
  box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
}
100% {
  box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
}
}

.previewText {
  font-size: 0.8 em;
  text-align: left;

}

.chapterListItem:hover {
  background-color:#cccccc;
}

.contentBlock::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); /* Centering */
  width: 50%; /* 50% of the parent's width */
  height: 2px; /* Border thickness */
  background-color: #f00; /* Border color */
}

.editorTextArea {
  border-radius:5px;
  margin:10px;
  padding:10px;
  border:2px solid #eeeeee;
  width: 95%;
  font-size:1.1em;
  overflow: hidden;
  resize: none;
}

.transcription-text {
  font-size: 1.2em;
  font-weight: 500;
}

.transcript-container {
  overflow: auto; 
}